<!--
 * @Author: your name
 * @Date: 2021-07-27 16:03:10
 * @LastEditTime: 2022-02-13 16:09:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/views/About.vue
-->
<template>
  <v-app>
    <v-container grid-list-xs>
      <h3>正在通过微信公众号验证</h3>
      <!--
      <h6>wechat-url:{{ url }}</h6>
      <h6>code:{{ code }}</h6>
      <h6>urls:{{ urls }}</h6>
      <div>
        <h6>openid:{{}}</h6>
      </div>
      <div>
        <h6>school-code:{{ scode }}</h6>
      </div>

      <h4>wechat:{{ wechat }}</h4>

      <div>
        <vue-qr ref="Qrcode" :text="urlsCode" qid="testQrId"></vue-qr>
      </div>
      <v-btn color="success" block @click="goWechat()"> 登录微信端学校项目平台 </v-btn>
      -->
    </v-container>
  </v-app>
</template>
<script>
//import VueQr from "vue-qr";

export default {
  //components: { VueQr },
  data() {
    return {
      url: "",
      urls: "",
      op: "",
      code: "",
      scode: "",
      urlsCode: "",
      //redirect_uri:"",
      state: "",
      wechat: {},
    };
  },
  created() {
    this.urlsCode = window.location.href;
    //localStorage.removeItem("usCookie");
    //localStorage.removeItem("uskey");
    this.state = this.$route.query.state ? this.$route.query.state : "/";
  },
  mounted() {
    this.wechatAppid();
    /*
    if (this.$sopen.isWechat() == true) {
      this.wechatAppid();
    }
    */
  },
  methods: {
    goWechat() {
      window.location.href = this.url;
    },
    wechatAppid() {
      let options = {
        module: "wechat",
        title: "appid",
        data: {
          //appid: this.$route.query.schoolid,
          appid: "1008",
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(options).then((res) => {
        //that.code = that.$route.path;
        that.wechat = res;
        let wechat = res.data.wechat;

        let redirect_uri = encodeURIComponent(
          window.location.protocol +
            "//" +
            window.location.host +
            "/weChat/oauth2?OP=" +
            wechat.scode
        );
        let url =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          wechat.appid +
          "&redirect_uri=" +
          redirect_uri +
          //"&response_type=code&scope=snsapi_base&state=" +
          "&response_type=code&scope=snsapi_userinfo&state=" +
          encodeURIComponent(that.state) +
          "#wechat_redirect";
        that.url = url;
        window.location.href = url;
      });
    },
    uscode() {
      var data = {
        module: "wechat",
        title: "uscode",
        data: {
          appid: this.op,
          code: this.code,
        },
      };
      let that = this;
      this.$sopen.requestApi(data).then((res) => {
        that.scode = res;
      });
    },
  },
};
</script>
